import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AMENDMENT_QUERY_KEYS, PAYRUN_QUERY_KEYS } from '@xemplo/gp-constants';
import { EditAmendmentPayload } from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { AmendmentAPIV2 } from './amendment-query.constants';
import { useEditAmendmentProps } from './amendment-query.types';

export const useEditAmendment = (props: useEditAmendmentProps) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(
    AmendmentAPIV2.editAmendment(props.amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: (payload: Partial<EditAmendmentPayload>) => {
      return client.patch(url, payload).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};
