import { ReactNode } from 'react';

export const InlineBannerSize = {
  Small: 'small',
  Large: 'large',
} as const;

export type InlineBannerSize = (typeof InlineBannerSize)[keyof typeof InlineBannerSize];

export const InlineBannerType = {
  Confirm: 'confirm',
  General: 'general',
  Warning: 'warning',
  Alert: 'alert',
} as const;

export type InlineBannerType = (typeof InlineBannerType)[keyof typeof InlineBannerType];

export type Link = {
  label: string;
  url: string;
  target?: string;
};

type LargeBanner = {
  size?: typeof InlineBannerSize.Large;
  body: ReactNode;
};

type SmallBanner = {
  size?: typeof InlineBannerSize.Small;
  body?: never;
};

export type InlineBannerProps = {
  type: InlineBannerType;
  title?: string;
  subTitle?: string;
  body?: ReactNode;
  link?: Link;
  isClosable?: boolean;
  onCloseCb?: () => void;
} & (LargeBanner | SmallBanner);
