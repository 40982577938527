import {
  Amendment,
  AmendmentStatusIds,
  AmendmentTypes,
  ChangeTaskTypes,
} from '@xemplo/gp-types';

const validAmendmentStatuses = [
  AmendmentStatusIds.CREATED,
  AmendmentStatusIds.SUBMITTED,
  AmendmentStatusIds.REJECTED,
  AmendmentStatusIds.RESUBMITTED,
  AmendmentStatusIds.UPDATED,
  AmendmentStatusIds.DRAFT,
];

export const showEditAmendmentButton = (amendment: Amendment) => {
  //These conditions will get removed as the edit form functionality is expanded
  if (amendment.amendmentTypeId === AmendmentTypes.RECURRING) {
    return false;
  }
  if (
    amendment.amendmentTaskTypeID === ChangeTaskTypes.TERMINATION ||
    amendment.amendmentTaskTypeID === ChangeTaskTypes.PAY_INSTRUCTION
  ) {
    return false;
  }
  return validAmendmentStatuses.includes(amendment.amendmentStatusID);
};
