import { useRef } from 'react';

import { useEditAmendment } from '@xemplo/amendment-query';
import { Button, ButtonSize, ButtonType } from '@xemplo/button';
import {
  EditAmendmentForm,
  generatePayload,
  getDirtyFieldValues,
  UseFormRef,
} from '@xemplo/edit-amendment-form';
import { useModal } from '@xemplo/modal';
import { ToastType, useToast } from '@xemplo/toast';

import { EditAmendmentModalProps } from './edit-amendment-modal.types';

export function EditAmendmentModal(props: Readonly<EditAmendmentModalProps>) {
  const { addToast } = useToast();
  const { mutateAsync } = useEditAmendment({
    amendmentId: props.amendmentId,
    onSuccess: () => {
      toggleModal(false);
      addToast({
        text: 'Amendment updated',
        type: ToastType.Confirmation,
      });
    },
    onError: (error) => {
      const errors = error.response?.data?.errors;
      const allErrorMessages = Object.values(errors ?? {})
        .flat()
        .join(', ')
        .trim();
      const simpleError = error.message;
      const displayError = allErrorMessages
        ? allErrorMessages
        : simpleError ?? 'An error occurred';
      openEditAmendmentModal(displayError);
    },
  });

  const { configureModal, toggleModal } = useModal();
  const useFormRef = useRef<UseFormRef>(null);

  const handleSubmit = async () => {
    await useFormRef.current?.handleSubmit((values) => {
      const dirtyFields = getDirtyFieldValues(
        values,
        useFormRef.current?.formState.dirtyFields
      );
      return mutateAsync(generatePayload(dirtyFields));
    })();
  };

  const openEditAmendmentModal = (error?: string | null) => {
    configureModal({
      body: {
        content: (
          <EditAmendmentForm
            amendmentId={props.amendmentId}
            ref={useFormRef}
            error={error}
          />
        ),
      },
      footer: {
        btnPrimary: {
          label: 'Save',
          onClickAsync: handleSubmit,
          type: ButtonType.Primary,
          ariaLabel: 'Save amendment button',
        },
        btnTertiary: {
          label: 'Cancel',
          onClick: () => toggleModal(false),
          type: ButtonType.Tertiary,
          ariaLabel: 'Cancel edit amendment button',
        },
      },
    });
  };

  return (
    <Button
      ariaLabel="Edit Amendment"
      type={ButtonType.Secondary}
      size={ButtonSize.Medium}
      onClick={() => openEditAmendmentModal()}
    >
      Edit
    </Button>
  );
}

export default EditAmendmentModal;
