import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '@xemplo/icon-button';
import {
  BodyXSmallRegular,
  BodyXSmallSemiBold,
  Colour,
  LinkHoverLight,
  LinkUnderline,
} from '@xemplo/style-constants';

export const Wrapper = styled.div`
  --banner-bg-color: ${Colour.Gray[50]};
  --banner-border-color: ${Colour.Gray[300]};
  --banner-icon-color: ${Colour.Gray[500]};
  ${BodyXSmallRegular};

  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  gap: 16px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background: var(--banner-bg-color);
  border: 1px solid var(--banner-border-color);
  color: ${Colour.Gray[600]};

  &.confirm {
    --banner-bg-color: ${Colour.Green[50]};
    --banner-border-color: ${Colour.Green[300]};
    --banner-icon-color: ${Colour.Green[500]};
  }

  &.general {
    --banner-bg-color: ${Colour.Blue[50]};
    --banner-border-color: ${Colour.Blue[300]};
    --banner-icon-color: ${Colour.Blue[500]};
  }

  &.warning {
    --banner-bg-color: ${Colour.Orange[50]};
    --banner-border-color: ${Colour.Orange[300]};
    --banner-icon-color: ${Colour.Orange[500]};
  }

  &.alert {
    --banner-bg-color: ${Colour.Red[50]};
    --banner-border-color: ${Colour.Red[300]};
    --banner-icon-color: ${Colour.Red[500]};
  }

  &.large {
    padding: 16px;

    & > svg {
      align-self: flex-start;
    }
  }

  & > svg {
    color: var(--banner-icon-color);
    font-size: 24px;
    flex: 0 0 24px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  &.large {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.span`
  ${BodyXSmallSemiBold};
`;
export const Link = styled(ReactRouterLink)`
  ${LinkUnderline};
  ${LinkHoverLight};
  &:visited,
  &:link,
  &:active {
    color: inherit;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  &.large {
    align-self: flex-start;
  }
`;

/**
 * The negative margin is needed because the design aligns the right
 * padding based on the icon. Since the icon has a hit area, the padding
 * gets slightly misaligned. This negative margin corrects that.
 */
export const CloseButton = styled(IconButton)`
  position: absolute;
  top: -8px;
  right: -8px;
`;
