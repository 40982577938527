import styled from 'styled-components';

import { Button, ButtonWithState } from '@xemplo/button';
import {
  BodySmallRegular,
  BodyStandardMedium,
  BodyXSmallRegular,
  Colour,
  HeadingStandardMedium,
  Shadow,
} from '@xemplo/style-constants';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 769px;
  gap: 32px;
  min-height: 568px;
`;
export const FormLabel = styled.div`
  ${BodyStandardMedium};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const OptionalLabel = styled.span`
  color: ${Colour.Gray[300]};
`;
export const HeaderContainer = styled.div`
  padding: 44px 40px 32px;
  display: flex;
  align-items: center;
  gap: 24px;
`;
export const HeaderTitle = styled.h1`
  ${HeadingStandardMedium};
  color: ${Colour.Gray[800]};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: ${Colour.White[100]};
  border-radius: 50%;
  border: 1px solid ${Colour.Gray[50]};
  border-radius: 12px;
  ${Shadow.Medium2};
`;

export const ErrorMessage = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${Colour.Red[300]};
  background: ${Colour.Red[50]};
  color: ${Colour.Gray[600]};
  justify-content: flex-start;
  ${BodyXSmallRegular};
  & > svg {
    color: ${Colour.Red[500]};
    height: 24px;
    width: 24px;
  }
`;

export const CustomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 40px 32px 40px;
  margin-top: auto;
`;

export const CustomButton = styled(Button)`
  width: 128px;
`;

export const CustomButtonWithState = styled(ButtonWithState)`
  width: 128px;
`;

export const LabelExtraInfo = styled.div`
  color: ${Colour.Gray[400]};
  ${BodySmallRegular};
  padding-bottom: 12px;
  margin-top: -8px;
`;

export const TerminatedToggle = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  ${BodySmallRegular};
  color: ${Colour.Gray[500]};
`;
export const AppliesToLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SingleLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
