import { EditAmendmentPayload } from '@xemplo/gp-types';

import { EditAmendmentFormValues } from './edit-amendment-form.types';

export const getDirtyFieldValues = (
  values: EditAmendmentFormValues,
  dirtyFields?: Partial<Record<keyof EditAmendmentFormValues, boolean | undefined>>
) => {
  if (!dirtyFields) {
    return {};
  }
  return Object.keys(dirtyFields).reduce((acc: Record<string, unknown>, key) => {
    acc[key] = values[key as keyof EditAmendmentFormValues];
    return acc;
  }, {} as Record<string, unknown>);
};

export const PLACEHOLDER_OPTION = '-1';

export const YesNoOptions = [
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' },
];

export const taxCalcOptions = [
  { key: PLACEHOLDER_OPTION, value: 'Select Tax Calculation Method' },
  { key: 'A', value: 'A' },
  { key: 'B(ii)', value: 'B(ii)' },
  { key: 'NotApplicable', value: 'NotApplicable' },
];

export const generatePayload = (values: EditAmendmentFormValues) => {
  const payload: EditAmendmentPayload = {
    amount: values.rate,
    units: values.units,
    lumpSumCalculationMethod: values.lumpSumCalculationMethod,
    lumpSumNumberOfPayPeriods: Number(values.lumpSumNumberOfPayPeriods),
    note: values.note,
  };
  if (values.lumpSum) {
    payload.isLumpSumPayments = values.lumpSum === 'Yes';
  }
  return payload;
};
