import { useMemo, useState } from 'react';
import classNames from 'classnames';

import { IconButtonSize, IconButtonTestId } from '@xemplo/icon-button';
import {
  DawnCircleAlert,
  DawnCircleInformation,
  DawnCircleTick,
  DawnCross16,
  DawnTriangleAlert,
} from '@xemplo/icons';

import * as S from './inline-banner.styles';
import {
  InlineBannerProps,
  InlineBannerSize,
  InlineBannerType,
} from './inline-banner.types';

const closeButtonId = 'inline-banner-close-btn';

export const InlineBannerTestId = {
  Container: 'inline-banner-container',
  IconWrapper: 'inline-banner-icon-wrapper',
  TextWrapper: 'inline-banner-text-wrapper',
  Title: 'inline-banner-title',
  SubTitle: 'inline-banner-sub-title',
  Body: 'inline-banner-body',
  Link: 'inline-banner-link',
  CloseButton: `${IconButtonTestId.Button(closeButtonId)}`,
};

const IconMap = {
  [InlineBannerType.Confirm]: DawnCircleTick,
  [InlineBannerType.General]: DawnCircleInformation,
  [InlineBannerType.Warning]: DawnCircleAlert,
  [InlineBannerType.Alert]: DawnTriangleAlert,
};

export function InlineBanner(props: Readonly<InlineBannerProps>) {
  const {
    type,
    title,
    subTitle,
    link,
    body,
    onCloseCb,
    isClosable = false,
    size = InlineBannerSize.Small,
  } = props;
  const [isVisible, setIsVisible] = useState(true);
  const Icon = useMemo(() => IconMap[type], [type]);
  const isLarge = size === InlineBannerSize.Large;

  const handleClose = () => {
    setIsVisible(false);
    onCloseCb?.();
  };

  if (!isVisible) return null;

  return (
    <S.Wrapper
      data-testid={InlineBannerTestId.Container}
      className={classNames(type, size)}
    >
      <Icon data-testid={InlineBannerTestId.IconWrapper} data-icon={Icon.name} />
      <S.TextWrapper
        data-testid={InlineBannerTestId.TextWrapper}
        className={classNames(size)}
      >
        {(title || subTitle) && (
          <div>
            {title && <S.Title data-testid={InlineBannerTestId.Title}>{title}</S.Title>}
            {subTitle && (
              <span data-testid={InlineBannerTestId.SubTitle}>{subTitle}</span>
            )}
          </div>
        )}
        {isLarge && body && <div data-testid={InlineBannerTestId.Body}>{body}</div>}
        {link && (
          <S.Link
            to={link.url}
            target={link.target}
            rel="noreferrer"
            data-testid={InlineBannerTestId.Link}
          >
            {link.label}
          </S.Link>
        )}
      </S.TextWrapper>
      {isClosable && (
        <S.CloseButtonWrapper className={classNames(size)}>
          <S.CloseButton
            id={closeButtonId}
            ariaLabel="Inline banner close button"
            size={IconButtonSize.Small}
            onClick={handleClose}
            naked
          >
            <DawnCross16 />
          </S.CloseButton>
        </S.CloseButtonWrapper>
      )}
    </S.Wrapper>
  );
}

export default InlineBanner;
