import styled from 'styled-components';

import {
  BodyLargeSemiBold,
  BodySmallMedium,
  BodySmallRegular,
  BodyStandardMedium,
  Colour,
} from '@xemplo/style-constants';

export const StyledForm = styled.form`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
`;

export const DetailRow = styled.div`
  ${BodySmallRegular};
  color: ${Colour.Gray[400]};
  display: grid;
  gap: 8px;
  & > div {
    ${BodySmallMedium};
    color: ${Colour.Gray[700]};
  }
`;

export const AmendmentType = styled.div`
  ${BodyLargeSemiBold};
  color: ${Colour.Gray[800]};
  grid-column: span 2;
`;

export const FormLabel = styled.div`
  ${BodyStandardMedium};
  color: ${Colour.Gray[800]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  &.full-width {
    grid-column: span 2;
  }
`;
export const OptionalLabel = styled.span`
  color: ${Colour.Gray[300]};
`;

export const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const LabelExtraInfo = styled.div`
  color: ${Colour.Gray[400]};
  ${BodySmallRegular};
  padding-bottom: 12px;
  margin-top: -8px;
`;
